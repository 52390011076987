import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../components/logo"

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You" />
    <header className="mastlogo bg-white text-white text-center">
      <div className="container d-flex align-items-center flex-column">
        <Logo />
      </div>
    </header>
    <header className="masthead bg-primary text-white text-center">
      <div className="container d-flex align-items-center flex-column">
        <div className="divider-custom divider-light">
          <h1 className="masthead-heading text-uppercase mb-0">Thank you for your inquiry</h1>

        </div>
        <p className="masthead-subheading font-weight-light mb-0">Need to hear from us sooner?</p>

        <div className="text-center mt-4">
          <a className="btn btn-xl btn-outline-light" href="tel:0432479731">
            Gives us a call
          </a>
        </div>
      </div>
    </header>
  </Layout>
)

export default ThankYouPage
