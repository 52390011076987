import * as React from "react"

const Logo = () => (
    <svg version="1.1" id="Layer_1"  x="0px" y="0px"
         viewBox="0 0 368.504 85.039">
        <rect fill="none" width="368.504" height="85.039" />
        <g>
            <g>
                <path d="M11.671,61.75c-0.943-0.943-1.414-2.199-1.414-3.763c0-1.324,0.403-2.471,1.211-3.45c0.809-0.98,1.896-1.47,3.268-1.47
			c1.052,0,1.914,0.154,2.587,0.46s1.01,0.972,1.01,1.999c0,0.441-0.072,0.93-0.22,1.468c-0.441-0.367-0.82-0.616-1.138-0.752
			c-0.318-0.135-0.673-0.203-1.065-0.203c-0.808,0-1.438,0.271-1.89,0.811c-0.453,0.535-0.679,1.175-0.679,1.908
			c0,0.855,0.257,1.498,0.771,1.925c0.515,0.431,1.089,0.644,1.725,0.644c0.587,0,1.083-0.115,1.486-0.35
			c0.404-0.231,0.766-0.673,1.084-1.321c0.317-0.647,0.623-1.597,0.917-2.843l5.763-26.211c-1.64,0.661-2.913,1.677-3.818,3.047
			c-0.905,1.371-1.358,3.035-1.358,4.993c0,0.905,0.086,1.535,0.258,1.89c0.17,0.354,0.257,0.557,0.257,0.606
			c-1.322,0-2.307-0.275-2.956-0.826c-0.648-0.552-0.972-1.463-0.972-2.734c0-1.566,0.643-3.091,1.926-4.572
			c1.285-1.479,2.931-2.679,4.938-3.597c2.006-0.917,4.013-1.375,6.02-1.375h1.468l-5.506,26.062
			c-0.636,3.012-1.688,5.275-3.157,6.792c-1.469,1.518-3.633,2.277-6.498,2.277C13.953,63.164,12.613,62.69,11.671,61.75z"/>
                <path d="M30.099,54.683c-0.723-0.733-1.084-1.836-1.084-3.305c0-0.609,0.099-1.406,0.294-2.385l2.496-11.784h5.288L34.449,49.69
			c-0.074,0.368-0.111,0.71-0.111,1.028c0,1.004,0.368,1.504,1.101,1.504c0.686,0,1.274-0.286,1.763-0.861
			c0.489-0.576,0.881-1.365,1.174-2.368l2.497-11.784h5.287L43.515,49.69c-0.098,0.368-0.146,0.761-0.146,1.174
			c0,0.49,0.116,0.839,0.349,1.048c0.232,0.208,0.618,0.311,1.156,0.311c0.685,0,1.279-0.295,1.78-0.882
			c0.502-0.584,0.9-1.369,1.193-2.348h1.541c-0.903,2.642-2.023,4.436-3.358,5.377c-1.334,0.942-2.674,1.414-4.02,1.414
			c-1.052,0-1.889-0.293-2.514-0.88c-0.624-0.588-0.998-1.458-1.121-2.607c-0.832,1.346-1.705,2.266-2.624,2.752
			c-0.917,0.491-1.854,0.735-2.809,0.735C31.769,55.784,30.82,55.416,30.099,54.683z"/>
                <path d="M49.059,55.105c-0.808-0.453-1.408-1.041-1.799-1.765c-0.391-0.72-0.587-1.474-0.587-2.257
			c0-0.807,0.189-1.51,0.569-2.108c0.379-0.6,0.837-1.034,1.376-1.303c0.954-1.712,1.787-3.446,2.497-5.196
			c0.71-1.749,1.382-3.639,2.02-5.67l5.433-0.735c0.122,3.134,0.33,6.522,0.624,10.167c0.122,1.471,0.183,2.534,0.183,3.194
			c0,0.563-0.05,1.029-0.146,1.396c1.15-0.659,2.031-1.271,2.643-1.835h1.542c-1.566,1.812-3.439,3.341-5.616,4.588
			c-0.711,0.759-1.584,1.315-2.625,1.669c-1.04,0.354-2.087,0.534-3.138,0.534C50.857,55.784,49.866,55.558,49.059,55.105z
			 M53.133,52.077c0.61-0.54,0.917-1.408,0.917-2.607c0-0.734-0.074-1.761-0.222-3.082c-0.243-2.791-0.404-4.664-0.476-5.618
			c-0.588,1.935-1.579,4.246-2.975,6.939c0.563,0.293,0.845,0.722,0.845,1.284c0,0.465-0.153,0.881-0.459,1.248
			c-0.306,0.366-0.691,0.552-1.156,0.552c-0.515,0-0.845-0.162-0.992-0.479c0,0.881,0.178,1.529,0.533,1.946
			c0.354,0.417,0.923,0.622,1.708,0.622C51.763,52.883,52.521,52.614,53.133,52.077z"/>
                <path d="M62.548,54.646c-0.599-0.758-0.9-1.764-0.9-3.012c0-0.635,0.074-1.283,0.221-1.944l2.35-11.013h-1.211l0.293-1.468h1.211
			l1.101-5.066l5.433-0.734c-0.22,0.93-0.343,1.481-0.368,1.653c-0.171,0.684-0.465,2.066-0.88,4.147H72l-0.294,1.468h-2.203
			L67.155,49.69c-0.123,0.539-0.184,0.967-0.184,1.283c0,0.883,0.427,1.323,1.284,1.323c0.441,0,0.759-0.036,0.955-0.11
			c-0.489,1.421-1.064,2.376-1.725,2.862c-0.662,0.491-1.383,0.735-2.167,0.735C64.071,55.784,63.146,55.405,62.548,54.646z"/>
                <path d="M88.959,30.602c-1.639,0.686-2.906,1.714-3.798,3.084c-0.896,1.371-1.342,3.022-1.342,4.956
			c0,0.905,0.086,1.535,0.257,1.89c0.172,0.354,0.258,0.557,0.258,0.606c-1.322,0-2.306-0.275-2.955-0.826
			c-0.649-0.552-0.973-1.463-0.973-2.734c0-1.566,0.642-3.091,1.927-4.572c1.285-1.479,2.93-2.679,4.938-3.597
			c2.006-0.917,4.012-1.375,6.019-1.375c0.344,0,0.833,0.024,1.469,0.072l-2.35,10.94h5.396l2.35-10.94h5.284l-5.835,27.46h-5.286
			l2.937-13.804h-5.396l-2.937,13.804h-5.285L88.959,30.602z"/>
                <path d="M105.166,54.683c-0.723-0.733-1.083-1.836-1.083-3.305c0-0.609,0.099-1.406,0.294-2.385l2.496-11.784h5.286l-2.643,12.481
			c-0.099,0.368-0.147,0.761-0.147,1.174c0,0.49,0.116,0.839,0.35,1.048c0.231,0.208,0.617,0.311,1.155,0.311
			c0.71,0,1.37-0.298,1.982-0.898c0.612-0.6,1.052-1.377,1.322-2.331h1.542c-0.906,2.642-2.068,4.436-3.488,5.377
			c-1.42,0.942-2.826,1.414-4.221,1.414C106.836,55.784,105.886,55.416,105.166,54.683z M108.451,34.383
			c-0.563-0.563-0.844-1.248-0.844-2.055c0-0.807,0.281-1.5,0.844-2.075c0.562-0.575,1.248-0.862,2.056-0.862
			c0.807,0,1.498,0.287,2.073,0.862c0.575,0.575,0.863,1.268,0.863,2.075c0,0.808-0.288,1.492-0.863,2.055
			c-0.575,0.564-1.266,0.844-2.073,0.844C109.699,35.227,109.013,34.947,108.451,34.383z"/>
                <path d="M116.672,37.209h5.286l-0.477,2.276c0.831-0.733,1.583-1.297,2.257-1.688s1.4-0.587,2.185-0.587
			c0.782,0,1.401,0.27,1.853,0.807c0.452,0.538,0.68,1.188,0.68,1.945c0,0.711-0.234,1.334-0.697,1.873
			c-0.466,0.539-1.115,0.807-1.947,0.807c-0.538,0-0.897-0.128-1.082-0.385c-0.184-0.257-0.324-0.63-0.421-1.119
			c-0.075-0.318-0.147-0.55-0.222-0.697c-0.073-0.146-0.208-0.222-0.404-0.222c-0.513,0-0.947,0.104-1.303,0.313
			c-0.354,0.208-0.814,0.569-1.375,1.083l-2.937,13.95h-5.286L116.672,37.209z"/>
                <path d="M128.969,54.37c-1.003-0.941-1.505-2.418-1.505-4.423c0-1.688,0.33-3.548,0.99-5.581c0.661-2.03,1.737-3.786,3.231-5.267
			c1.493-1.479,3.389-2.221,5.689-2.221c2.692,0,4.038,1.175,4.038,3.525c0,1.37-0.392,2.629-1.174,3.779
			c-0.784,1.15-1.824,2.075-3.121,2.772c-1.297,0.696-2.68,1.095-4.148,1.191c-0.05,0.539-0.074,0.905-0.074,1.104
			c0,2.03,0.759,3.046,2.276,3.046c0.685,0,1.419-0.184,2.202-0.552c0.783-0.367,1.494-0.832,2.129-1.394
			c-0.661,3.622-2.778,5.433-6.351,5.433C131.367,55.784,129.973,55.313,128.969,54.37z M135.853,45.469
			c0.819-0.588,1.479-1.341,1.982-2.259c0.501-0.918,0.753-1.876,0.753-2.881c0-1.004-0.307-1.505-0.918-1.505
			c-0.857,0-1.708,0.777-2.552,2.331c-0.844,1.554-1.474,3.3-1.89,5.232C134.157,46.363,135.032,46.058,135.853,45.469z"/>
            </g>
            <g>
                <path d="M204.293,30.602c-1.641,0.686-2.907,1.714-3.798,3.084c-0.895,1.371-1.34,3.022-1.34,4.956
			c0,0.905,0.087,1.535,0.258,1.89s0.256,0.557,0.256,0.606c-1.322,0-2.308-0.275-2.956-0.826c-0.648-0.552-0.972-1.463-0.972-2.734
			c0-1.566,0.642-3.091,1.926-4.572c1.287-1.479,2.933-2.679,4.937-3.597c2.009-0.917,4.015-1.375,6.023-1.375
			c0.342,0,0.829,0.024,1.465,0.072l-2.348,10.94h5.396l2.35-10.94h5.285l-5.837,27.46h-5.285l2.936-13.804h-5.395l-2.938,13.804
			h-5.287L204.293,30.602z"/>
                <path d="M220.626,54.354c-0.929-0.955-1.395-2.449-1.395-4.479c0-1.812,0.362-3.712,1.083-5.71c0.723-1.992,1.8-3.682,3.231-5.064
			c1.431-1.383,3.14-2.074,5.122-2.074c1.001,0,1.748,0.171,2.238,0.514c0.488,0.345,0.733,0.796,0.733,1.359v0.256l0.405-1.945
			h5.285l-2.644,12.481c-0.098,0.368-0.146,0.761-0.146,1.174c0,1.055,0.5,1.579,1.504,1.579c0.685,0,1.276-0.317,1.779-0.954
			c0.502-0.636,0.901-1.471,1.195-2.496h1.54c-0.904,2.642-2.025,4.436-3.356,5.377c-1.335,0.942-2.676,1.414-4.023,1.414
			c-1.026,0-1.852-0.288-2.477-0.863c-0.623-0.572-0.997-1.414-1.119-2.515c-0.708,1.004-1.497,1.817-2.368,2.441
			c-0.868,0.624-1.901,0.937-3.1,0.937C222.72,55.784,221.559,55.308,220.626,54.354z M228.317,51.361
			c0.601-0.576,1.01-1.365,1.229-2.368l1.765-8.296c0-0.317-0.124-0.631-0.369-0.938c-0.244-0.305-0.622-0.458-1.135-0.458
			c-0.981,0-1.86,0.569-2.644,1.708c-0.783,1.136-1.396,2.507-1.836,4.108c-0.442,1.604-0.661,3.019-0.661,4.24
			c0,1.225,0.177,2.01,0.533,2.353c0.353,0.343,0.787,0.512,1.301,0.512C227.111,52.223,227.717,51.937,228.317,51.361z"/>
                <path d="M248.983,54.591c-0.696-0.794-1.045-1.78-1.045-2.956c0-0.512,0.061-1.096,0.184-1.742
			c0.122-0.648,0.252-1.278,0.385-1.891c0.135-0.611,0.226-1.002,0.277-1.176c0.193-0.855,0.379-1.699,0.55-2.533
			c0.172-0.829,0.258-1.503,0.258-2.019c0-1.248-0.443-1.87-1.322-1.87c-0.64,0-1.201,0.311-1.689,0.934
			c-0.49,0.626-0.881,1.439-1.175,2.444l-2.494,11.782h-5.287l3.892-18.355h5.286l-0.406,1.909c1.226-1.37,2.657-2.055,4.297-2.055
			c1.247,0,2.24,0.342,2.972,1.027c0.734,0.687,1.103,1.726,1.103,3.12c0,0.712-0.086,1.499-0.258,2.368
			c-0.172,0.868-0.417,1.927-0.733,3.175c-0.197,0.758-0.374,1.499-0.533,2.223c-0.159,0.722-0.239,1.29-0.239,1.704
			c0,0.49,0.112,0.87,0.332,1.14c0.219,0.271,0.598,0.403,1.138,0.403c0.731,0,1.32-0.263,1.761-0.79
			c0.441-0.523,0.882-1.339,1.321-2.439h1.545c-0.908,2.693-1.955,4.497-3.142,5.413c-1.186,0.918-2.416,1.378-3.688,1.378
			C250.776,55.784,249.682,55.387,248.983,54.591z"/>
                <path d="M258.476,54.354c-0.931-0.955-1.396-2.449-1.396-4.479c0-1.812,0.359-3.712,1.081-5.71
			c0.723-1.992,1.799-3.682,3.231-5.064c1.434-1.383,3.139-2.074,5.121-2.074c1.004,0,1.749,0.171,2.24,0.514
			c0.487,0.345,0.731,0.796,0.731,1.359v0.146l1.801-8.443l5.432-0.734l-4.22,19.823c-0.101,0.368-0.148,0.761-0.148,1.174
			c0,0.49,0.116,0.839,0.349,1.048c0.233,0.208,0.62,0.311,1.157,0.311c0.685,0,1.279-0.295,1.78-0.882
			c0.503-0.584,0.899-1.369,1.193-2.348h1.54c-1.491,4.381-3.951,6.571-7.376,6.571c-1.006,0-1.818-0.271-2.443-0.81
			c-0.624-0.537-1.009-1.321-1.155-2.349c-0.637,0.979-1.4,1.787-2.294,2.422c-0.895,0.64-1.938,0.956-3.139,0.956
			C260.566,55.784,259.405,55.308,258.476,54.354z M266.127,51.416c0.601-0.539,1.012-1.286,1.231-2.238v-0.185l1.798-8.405
			c-0.219-0.857-0.723-1.286-1.504-1.286c-0.979,0-1.86,0.569-2.644,1.708c-0.783,1.136-1.396,2.507-1.834,4.108
			c-0.441,1.604-0.663,3.019-0.663,4.24c0,1.225,0.179,2.01,0.533,2.353c0.353,0.343,0.789,0.512,1.303,0.512
			C264.936,52.223,265.53,51.952,266.127,51.416z"/>
                <path d="M279.618,63.823c-0.785-0.612-1.176-1.493-1.176-2.644c0-1.396,0.563-2.515,1.689-3.358
			c1.125-0.843,2.557-1.548,4.294-2.112l0.442-1.98c-0.713,0.782-1.435,1.32-2.168,1.614c-0.733,0.295-1.479,0.441-2.238,0.441
			c-1.177,0-2.123-0.368-2.846-1.102c-0.721-0.733-1.082-1.836-1.082-3.305c0-0.609,0.096-1.406,0.293-2.385l2.496-11.784h5.285
			l-2.642,12.481c-0.073,0.293-0.11,0.599-0.11,0.917c0,1.079,0.367,1.615,1.102,1.615c0.637,0,1.197-0.274,1.688-0.826
			c0.489-0.55,0.882-1.276,1.177-2.182l2.566-12.005h5.289l-3.634,17.035c1.171-0.394,2.115-0.981,2.824-1.765
			c0.708-0.783,1.31-1.944,1.801-3.486h1.54c-0.661,2.177-1.553,3.775-2.681,4.791c-1.125,1.016-2.397,1.707-3.815,2.074
			l-0.551,2.643c-0.491,2.37-1.336,4.006-2.534,4.9c-1.199,0.895-2.559,1.34-4.074,1.34
			C281.379,64.741,280.398,64.436,279.618,63.823z M282.919,61.033c0.392-0.564,0.699-1.371,0.92-2.425l0.221-1.063
			c-2.4,0.882-3.598,1.92-3.598,3.12c0,0.318,0.11,0.601,0.33,0.845c0.219,0.244,0.525,0.368,0.916,0.368
			C282.125,61.879,282.529,61.597,282.919,61.033z"/>
                <path d="M313.701,54.591c-0.697-0.794-1.047-1.78-1.047-2.956c0-0.512,0.063-1.096,0.186-1.742
			c0.123-0.648,0.249-1.278,0.385-1.891c0.135-0.611,0.224-1.002,0.273-1.176c0.196-0.855,0.381-1.699,0.554-2.533
			c0.171-0.829,0.255-1.503,0.255-2.019c0-1.248-0.44-1.87-1.322-1.87c-0.636,0-1.198,0.311-1.688,0.934
			c-0.489,0.626-0.88,1.439-1.174,2.444l-2.496,11.782h-5.285l2.677-12.665c0.075-0.294,0.113-0.6,0.113-0.918
			c0-1.076-0.368-1.615-1.104-1.615c-0.687,0-1.278,0.312-1.779,0.936c-0.503,0.624-0.899,1.449-1.194,2.48l-2.494,11.782h-5.288
			l3.893-18.355h5.284l-0.401,1.909c1.246-1.395,2.738-2.093,4.477-2.093c2.153,0,3.414,1.054,3.782,3.157
			c1.345-2.079,3.046-3.12,5.102-3.12c1.249,0,2.239,0.342,2.974,1.027c0.733,0.687,1.102,1.726,1.102,3.12
			c0,0.712-0.086,1.499-0.258,2.368c-0.171,0.868-0.417,1.927-0.732,3.175c-0.197,0.758-0.375,1.499-0.533,2.223
			c-0.16,0.722-0.239,1.29-0.239,1.704c0,0.49,0.111,0.87,0.329,1.14c0.222,0.271,0.601,0.403,1.141,0.403
			c0.731,0,1.319-0.263,1.761-0.79c0.439-0.523,0.883-1.339,1.321-2.439h1.54c-0.904,2.693-1.95,4.497-3.137,5.413
			c-1.188,0.918-2.416,1.378-3.689,1.378C315.495,55.784,314.4,55.387,313.701,54.591z"/>
                <path d="M323.19,54.354c-0.931-0.955-1.395-2.449-1.395-4.479c0-1.812,0.359-3.712,1.08-5.71c0.724-1.992,1.801-3.682,3.231-5.064
			c1.434-1.383,3.139-2.074,5.122-2.074c1.003,0,1.748,0.171,2.239,0.514c0.487,0.345,0.733,0.796,0.733,1.359v0.256l0.404-1.945
			h5.286l-2.644,12.481c-0.098,0.368-0.146,0.761-0.146,1.174c0,1.055,0.501,1.579,1.505,1.579c0.687,0,1.278-0.317,1.781-0.954
			c0.5-0.636,0.898-1.471,1.192-2.496h1.54c-0.903,2.642-2.025,4.436-3.356,5.377c-1.335,0.942-2.674,1.414-4.021,1.414
			c-1.029,0-1.854-0.288-2.479-0.863c-0.624-0.572-0.997-1.414-1.117-2.515c-0.711,1.004-1.501,1.817-2.367,2.441
			c-0.871,0.624-1.903,0.937-3.104,0.937C325.282,55.784,324.121,55.308,323.19,54.354z M330.882,51.361
			c0.598-0.576,1.009-1.365,1.229-2.368l1.761-8.296c0-0.317-0.12-0.631-0.366-0.938c-0.245-0.305-0.624-0.458-1.137-0.458
			c-0.979,0-1.86,0.569-2.645,1.708c-0.782,1.136-1.395,2.507-1.834,4.108c-0.443,1.604-0.662,3.019-0.662,4.24
			c0,1.225,0.177,2.01,0.534,2.353c0.352,0.343,0.787,0.512,1.303,0.512C329.674,52.223,330.279,51.937,330.882,51.361z"/>
                <path d="M351.382,54.61c-0.587-0.785-0.882-1.775-0.882-2.976c0-0.512,0.061-1.096,0.185-1.742
			c0.122-0.648,0.251-1.278,0.384-1.891c0.136-0.611,0.228-1.002,0.277-1.176c0.194-0.855,0.379-1.699,0.55-2.533
			c0.171-0.829,0.259-1.503,0.259-2.019c0-1.248-0.443-1.87-1.321-1.87c-0.641,0-1.203,0.311-1.688,0.934
			c-0.492,0.626-0.884,1.439-1.177,2.444l-2.494,11.782h-5.288l3.892-18.355h5.286l-0.405,1.909c1.225-1.37,2.656-2.055,4.297-2.055
			c1.246,0,2.238,0.342,2.972,1.027c0.734,0.687,1.102,1.726,1.102,3.12c0,0.712-0.087,1.499-0.259,2.368
			c-0.171,0.868-0.416,1.927-0.731,3.175c-0.198,0.758-0.375,1.499-0.534,2.223c-0.159,0.722-0.237,1.29-0.237,1.704
			c0,0.49,0.111,0.87,0.33,1.14c0.222,0.271,0.601,0.403,1.138,0.403c0.588,0,0.992-0.073,1.212-0.218
			c-0.149,1.246-0.594,2.188-1.341,2.823c-0.745,0.64-1.645,0.956-2.698,0.956C352.911,55.784,351.968,55.392,351.382,54.61z"/>
            </g>
            <g>
                <g>
                    <path fill="#781214" d="M184.039,34.236L184.039,34.236V22.51h-6.637v5.755l0,0l-8.851-7.967L146.422,40.21h6.64l15.489-13.96
				l15.488,13.96h6.636L184.039,34.236z"/>
                    <g>
                        <path fill="#781214" d="M177.949,55.703h-6.283v-0.606c-1.496,0.14-2.987,0.277-4.481,0.418
					c-1.951,0.171-3.433,0.259-4.448,0.259c-2.393,0-3.587-0.708-3.587-2.131v-6.31c0-1.442,1.235-2.166,3.704-2.166h8.867v-4.018
					h-6.284v2.495h-5.942v-3.276c0-1.443,1.235-2.166,3.703-2.166h11.106c2.43,0,3.646,0.723,3.646,2.166V55.703z M171.72,52.553
					v-4.698h-6.284v4.834L171.72,52.553z"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>)

export default Logo;